export const METAMASK_CONNECT = "METAMASK_CONNECT";
export const metamaskConnect = (value) => {
  return {
    type: METAMASK_CONNECT,
    payload: value,
  };
};

export const GET_BALANCE = "GET_BALANCE";
export const getBalance = (balance) => {
  return {
    type: GET_BALANCE,
    payload: balance,
    meta: {
      asPromise: true,
    },
  };
};

export const GET_SIGN = "GET_SIGN";
export const getSign = (sign) => {
  return {
    type: GET_SIGN,
    payload: sign,
    meta: {
      asPromise: true,
    },
  };
};
