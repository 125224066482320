import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { requestsPromiseMiddleware } from "redux-saga-requests";
import thunk from "redux-thunk";
import { signOutMiddleware } from "./middlewares/logout";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
  requestsPromiseMiddleware(),
  thunk,
  createLogger(),
  signOutMiddleware,
];

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default store;
