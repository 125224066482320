import axios from "axios";
import { createRequestInstance, watchRequests } from "redux-saga-requests";
import { createDriver } from "redux-saga-requests-axios";
import { CONSTANTS } from "constants/global.constants";
const { AUTH_TOKEN } = CONSTANTS;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(AUTH_TOKEN)
    ? JSON.parse(localStorage.getItem(AUTH_TOKEN))
    : "";
  config.headers.Authorization = token;

  return config;
});

export default function* rootSaga() {
  yield createRequestInstance({ driver: createDriver(axios) });
  yield watchRequests({ takeLatest: true });
}
