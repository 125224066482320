import React from "react";

import { lazyLoad } from "utils/loadable";
import Fallback from "components/Fallback";

export const MainOutlet = lazyLoad(
  () => import("./MainOutlet"),
  (module) => module.default,
  { fallback: <Fallback /> }
);
