import { useState, useEffect } from "react";
import BigNumber from "extendedBN";

export const useInputHandlerWithMax = (
  balanceInput,
  allowanceInput,
  decimalsInput = 18
) => {
  const [value, setValue] = useState("0");
  const [inputValue, setInputValue] = useState("");
  const [shouldApprove, setShouldApprove] = useState(false);

  const decimals = Number(decimalsInput) ?? 18;
  const regex = new RegExp(`^[0-9]*[.,]?[0-9]{0,${decimals}}$`);
  const tenPowDecimals = new BigNumber(10).pow(decimals);

  const convertToBigNumber = (value) => new BigNumber(value.toString());
  const formatValue = (value, isParse) => {
    const bnValue = convertToBigNumber(value);
    return isParse
      ? bnValue.multipliedBy(tenPowDecimals)
      : bnValue.dividedBy(tenPowDecimals);
  };

  const balance = convertToBigNumber(balanceInput ?? "0");
  const allowance =
    allowanceInput != null ? convertToBigNumber(allowanceInput) : null;

  useEffect(() => {
    allowanceCheck(convertToBigNumber(value));
  }, [allowanceInput]);

  const onInputChange = (event) => {
    const valueFromInput = event.target.value.replace(/,/g, ".");

    if (regex.test(valueFromInput)) {
      if (valueFromInput.startsWith(".")) {
        setInputValue("0" + valueFromInput);
        balanceCheck(formatValue("0" + valueFromInput, true));
      } else if (valueFromInput === "" || valueFromInput === " ") {
        setInputValue("");
        balanceCheck(formatValue("0", true));
      } else {
        setInputValue(valueFromInput);
        balanceCheck(formatValue(valueFromInput, true));
      }
    }
  };

  const maxHandler = () => {
    allowanceCheck(balance);
    setInputValue(formatValue(balance, false).toFormatExtended(decimals));
    setValue(balance.toString());
  };

  const allowanceCheck = (value) => {
    if (allowance) {
      setShouldApprove(value.isGreaterThan(allowance));
    } else {
      setShouldApprove(false);
    }
  };

  const balanceCheck = (value) => {
    if (value.isGreaterThan(balance)) {
      maxHandler();
    } else {
      allowanceCheck(value);
      setValue(value.toString());
    }
  };

  const reset = () => {
    setValue("0");
    setInputValue("");
    setShouldApprove(false);
  };

  return { onInputChange, value, inputValue, shouldApprove, maxHandler, reset };
};

{
  /* <input
inputMode="decimal"
pattern="^(?!\.)[0-9]*[.,]?[0-9]*$"
className={}
value={}
onChange={}
/> */
}
