import { STATE_STATUSES } from "constants/statuses.constants";

export const errorReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
});

export const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
});
