import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import styles from "./BuyModal.module.scss";
import { IMAGES } from "assets/images";
import { useSelector, useDispatch } from "react-redux";
import { setBuyBar } from "store/modals/actions";
import { useAccount, useNetwork, useWalletClient } from "wagmi";
import { API_CONFIG } from "configs/api.config";
import { bsc } from "wagmi/chains";
import axios from "axios";

const BuyModal = () => {
  const { isOpenedBuybar: get } = useSelector((state) => state.modals);
  const { URL } = API_CONFIG;
  const dispatch = useDispatch();
  const { data: walletClient } = useWalletClient();
  const { isSingularityMounted } = useSelector((state) => state.utils);
  const [isRightChain, setRightChain] = useState(true);
  const [stage, setStage] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const { address } = useAccount();
  const { chain } = useNetwork();

  const onCloseModal = () => {
    dispatch(setBuyBar(false));
    setStage(0);
    setSelectedOption(null);
    setInputValue(0);
  };

  useEffect(() => {
    if (stage !== 3) {
      setSelectedOption(null);
    }
  }, [stage]);

  const inputChangeHandler = ({ target: { value } }) => {
    const targetValue = Number(value);
    if (!isNaN(targetValue) && targetValue >= 0) {
      setInputValue(targetValue);
    } else if (value === "") {
      setInputValue("");
    }
  };

  useEffect(() => {
    if (chain?.id === bsc.id && address) {
      setRightChain(true);
    }
  }, [chain, chain?.id]);

  const singularitySelectorHandler = (option) => {
    setSelectedOption(option);
    setStage(3);
  };

  const buyHandler = () => {
    if (selectedOption && inputValue > 0 && address) {
      buyBySingularity({
        transactionLabel: selectedOption.action.transactionLabel,
        transactionIconLink: selectedOption.action.transactionIconLink,
        clientRequestedAssetId: selectedOption.action.clientRequestedAssetId,
        clientRequestedAssetQuantity: inputValue.toString(),
        address: address,
      });
    }
  };

  async function isUserLoggedIn() {
    const userData = await window.SingularityEvent.getConnectUserInfo();
    if (userData.metaData) {
      return true;
    } else {
      return false;
    }
  }

  async function startTransaction(requestDataString, data) {
    if (await isUserLoggedIn()) {
      window.SingularityEvent.transactionFlow(requestDataString, data);
    } else {
      window.SingularityEvent.subscribe("SingularityEvent-login", () => {
        startTransaction(requestDataString);
      });

      await window.SingularityEvent.loginWithProvider(walletClient);
    }
  }

  const buyBySingularity = ({
    transactionLabel,
    transactionIconLink,
    clientRequestedAssetId,
    clientRequestedAssetQuantity,
    address,
  }) => {
    const body = {
      clientReferenceId: Math.floor(Math.random() * 100000).toString(),
      singularityTransactionType: "RECEIVE",
      transactionLabel,
      transactionDescription: "",
      transactionIconLink,
      clientReceiveObject: {
        clientRequestedAssetId, //See sample list below
        clientRequestedAssetQuantity,
        address,
      },
    };

    try {
      axios
        .post(`${URL}${"/access/signature"}`, {
          data: body,
        })
        .then((res) => {
          if (res && res.data) {
            const { data } = res;
            const requestString = JSON.stringify(body);
            startTransaction(requestString, data);
          }
        });
    } finally {
      onCloseModal();
    }
  };

  const zeroStageOptions = [
    {
      title: "Singularity",
      logo: IMAGES.sing,
      stage: 1,
    },
    {
      title: "Pancake",
      logo: IMAGES.pancakeLogo,
      stage: 2,
    },
  ];

  const firstStageOptions = [
    {
      title: "BNB",
      logo: IMAGES.bnbLogo,
      action: {
        transactionLabel: "Buy BNB",
        transactionIconLink:
          "https://singularity-icon-assets.s3.ap-south-1.amazonaws.com/currency/bnb.svg",
        clientRequestedAssetId: 87,
        clientRequestedAssetQuantity: "1",
        address: address,
      },
    },
    {
      title: "RPG",
      logo: IMAGES.rpgLogo,
      action: {
        transactionLabel: "Buy RPG",
        transactionIconLink:
          "https://singularity-icon-assets.s3.ap-south-1.amazonaws.com/currency/rpg.svg",
        clientRequestedAssetId: 86,
        clientRequestedAssetQuantity: "1",
        address: address,
      },
    },
  ];

  const secondStageOptions = [
    {
      title: "RPG",
      logo: IMAGES.rpgLogo,
      link: "https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x01e0d17a533e5930a349c2bb71304f04f20ab12b",
    },
    {
      title: "BUSD-RPG LP",
      logo: IMAGES.rpgBnbLp,
      link: "https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x01E0d17a533E5930A349C2BB71304F04F20AB12B",
    },
  ];

  const zeroStageOptionsCreator = () => {
    return zeroStageOptions.map((option, index) => {
      const isBlocked =
        option.title === zeroStageOptions[0].title && !isSingularityMounted;
      return (
        <div
          className={
            isBlocked ? styles.buyOptionWrapperBlocked : styles.buyOptionWrapper
          }
          onClick={isBlocked ? () => {} : () => setStage(option.stage)}
          key={index}
        >
          <img src={option.logo} alt="" className={styles.platformLogo} />
          <div className={styles.optionText}>{option.title}</div>
        </div>
      );
    });
  };

  const firstStageOptionsCreator = () => {
    return firstStageOptions.map((option, index) => {
      return (
        <div
          className={styles.buyOptionWrapper}
          key={index}
          onClick={() => singularitySelectorHandler(option)}
        >
          <img src={option.logo} alt="" className={styles.platformLogo} />
          <div className={styles.optionText}>{option.title}</div>
        </div>
      );
    });
  };

  const secondStageOptionsCreator = () => {
    return secondStageOptions.map((option, index) => {
      return (
        <a
          className={styles.buyOptionWrapper}
          key={index}
          target="_blank"
          rel="noreferrer"
          href={option.link}
        >
          <img src={option.logo} alt="" className={styles.platformLogo} />
          <div className={styles.optionText}>{option.title}</div>
        </a>
      );
    });
  };

  const stageHandler = () => {
    if (stage === 0) {
      return (
        <div className={styles.wrapper}>
          <h1 className={styles.header}>TOKENS BUY BAR</h1>
          <div className={styles.buyOptionsWrapper}>
            {zeroStageOptionsCreator()}
          </div>
        </div>
      );
    }

    if (stage === 1) {
      return (
        <div className={styles.wrapper}>
          <h1 className={styles.header}>BUY BY SINGULARITY</h1>
          <div className={styles.buyOptionsWrapper}>
            <div
              className={styles.buyOptionWrapper}
              onClick={() => setStage(0)}
            >
              <img
                src={IMAGES.backArrow}
                alt=""
                className={styles.platformLogo}
                style={{ scale: "0.8" }}
              />
              <div className={styles.optionText}>Back</div>
            </div>
            {firstStageOptionsCreator()}
          </div>
        </div>
      );
    }

    if (stage === 2) {
      return (
        <div className={styles.wrapper}>
          <h1 className={styles.header}>BUY BY PANCAKE</h1>
          <div className={styles.buyOptionsWrapper}>
            <div
              className={styles.buyOptionWrapper}
              onClick={() => setStage(0)}
            >
              <img
                src={IMAGES.backArrow}
                alt=""
                className={styles.platformLogo}
                style={{ scale: "0.8" }}
              />
              <div className={styles.optionText}>Back</div>
            </div>
            {secondStageOptionsCreator()}
          </div>
        </div>
      );
    }

    if (stage === 3) {
      if (selectedOption) {
        return (
          <div className={styles.wrapper}>
            <h1
              className={styles.header}
            >{`CHOSE THE AMOUNT OF ${selectedOption.title}`}</h1>
            <div className={styles.buyOptionsWrapper}>
              <div
                className={styles.buyOptionWrapper}
                onClick={() => {
                  setInputValue(0);
                  setStage(1);
                }}
              >
                <img
                  src={IMAGES.backArrow}
                  alt=""
                  className={styles.platformLogo}
                  style={{ scale: "0.8" }}
                />
                <div className={styles.optionText}>Back</div>
              </div>
              <div className={styles.formWrapper}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={inputChangeHandler}
                  className={styles.tokenInput}
                />
                <div className={styles.maxBtnWrapper} onClick={buyHandler}>
                  <p className={styles.maxBtnLabel}>BUY</p>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <Modal
      open={get}
      showCloseIcon={false}
      onClose={onCloseModal}
      center
      animationDuration={0}
      classNames={{
        modal: styles.icwModal,
      }}
    >
      {isRightChain ? (
        <div className={styles.fromsWrapper}>{stageHandler()}</div>
      ) : (
        <div className={styles.fromsWrapper}>
          <div className={styles.wrapper}>
            <h1 className={styles.header}>CHAIN IS NOT SUPPORTED (BSC ONLY)</h1>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BuyModal;
