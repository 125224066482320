import { passportInstance } from "utils/imx";
import { useDispatch } from "react-redux";
import { setConnectionLoading, setConnectionType } from "store/vars/actions";
import { BrowserProvider } from "ethers";

export const useImx = () => {
  const dispatch = useDispatch();

  const getImxSigner = async () => {
    try {
      const provider = passportInstance.connectEvm();
      const web3Provider = new BrowserProvider(provider);
      const signer = await web3Provider.getSigner();
      return signer;
    } catch (error) {
      return undefined;
    }
  };

  const signDataWithImx = async (message) => {
    const signer = await getImxSigner();
    let signature = undefined;
    if (signer) {
      try {
        signature = await signer.signMessage(message);
      } catch (error) {
        if (error.code === -32003) {
          console.log("User denied signature");
        }
      }
    }
    return signature;
  };

  const loginImx = async () => {
    dispatch(setConnectionLoading(true));
    try {
      const provider = await passportInstance.connectImx();
      const isRegistered = await provider.isRegisteredOffchain();
      if (!isRegistered) {
        await provider.registerOffchain();
      }
      dispatch(setConnectionType("imx"));
    } catch (error) {
      console.error("Error logging in with IMX: ", error);
      dispatch(setConnectionType(undefined));
    }
    dispatch(setConnectionLoading(false));
  };

  return { signDataWithImx, loginImx, getImxSigner };
};
