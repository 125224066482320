import React, { useState, useEffect } from "react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  connectorsForWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  metaMaskWallet,
  coinbaseWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { useSingularityConnect, useCheckAddressAndConnection } from "hooks";
import { wombatWallet } from "configs/wallets";
import Fallback from "components/Fallback";

const { chains, publicClient } = configureChains([bsc], [publicProvider()]);

const projectId = "08dbacc906d0270f8c32645bfb14f797";

export const Web3Helpers = ({ children }) => {
  useCheckAddressAndConnection();
  useSingularityConnect();
  return <>{children}</>;
};

export const Web3Provider = ({ children }) => {
  const injectedWalletHandler = () => {
    if (window?.ethereum?.isWombat) {
      return wombatWallet({ chains });
    } else {
      return metaMaskWallet({ chains, projectId });
    }
  };

  const connectors = connectorsForWallets([
    {
      groupName: "Recommended",
      wallets: [
        injectedWalletHandler(),
        coinbaseWallet({ projectId, chains }),
        trustWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        locale="default"
        chains={chains}
        initialChain={bsc}
        theme={darkTheme()}
      >
        <Web3Helpers>{children}</Web3Helpers>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export const DelayedWeb3Provider = ({ children }) => {
  const [isEthereumReady, setIsEthereumReady] = useState(false);

  useEffect(() => {
    let totalWaitTime = 0;
    const maxWaitTime = 500;

    const checkEthereumAvailability = () => {
      if (window?.ethereum || totalWaitTime >= maxWaitTime) {
        setIsEthereumReady(true);
      } else {
        totalWaitTime += 100;
        setTimeout(checkEthereumAvailability, 100);
      }
    };

    checkEthereumAvailability();

    return () => {
      clearTimeout(checkEthereumAvailability);
    };
  }, []);

  if (!isEthereumReady) {
    return <Fallback />;
  }

  return <Web3Provider>{children}</Web3Provider>;
};
