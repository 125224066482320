import useSWR from "swr";
import { setImxUser, setConnectionType } from "store/vars/actions";
import { logout } from "store/auth/actions";
import { passportInstance } from "utils/imx";

import { useSelector, useDispatch } from "react-redux";

export const useImxUser = () => {
  const { connectionType } = useSelector((state) => state.vars);
  const dispatch = useDispatch();

  const {
    data = undefined,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(
    connectionType === "imx" ? `/getImxUser/${connectionType}` : null,
    async () => {
      return getImxUser();
    },
    { revalidateOnFocus: false, refreshInterval: 60000 }
  );

  const getImxUser = async () => {
    try {
      const userProfile = await passportInstance.getUserInfo();
      const accessToken = await passportInstance.getAccessToken();
      const idToken = await passportInstance.getIdToken();

      if (userProfile && accessToken && idToken) {
        const profile = {
          ...userProfile,
          accessToken,
          idToken,
        };
        dispatch(setImxUser(profile));
        dispatch(setConnectionType("imx"));
      } else {
        throw new Error();
      }
      return userProfile;
    } catch (error) {
      console.error("Error getting IMX user: ", error);
      dispatch(setImxUser(undefined));
      if (connectionType === "imx") {
        dispatch(setConnectionType(undefined));
        dispatch(logout());
      }
      return undefined;
    }
  };

  return {
    imxUser: data,
    isLoading: isLoading || isValidating,
    updateImxUser: mutate,
  };
};
