import { combineReducers } from "redux";

import auth from "store/auth/reducers";
import sidebar from "store/sidebar/reducers";
import wallet from "store/wallet/reducer";
import configs from "store/configs/reducers";
import stats from "store/stats/reducer";
import users from "store/users/reducers";
import bridge from "store/bridge/reducers";
import modals from "store/modals/reducers";
import utils from "store/utils/reducers";
import vars from "store/vars/reducers";

export default combineReducers({
  auth,
  sidebar,
  users,
  wallet,
  configs,
  stats,
  bridge,
  modals,
  utils,
  vars,
});
