export const SET_BUY_BAR = "SET_BUY_BAR";
export const setBuyBar = (value) => {
  return {
    type: SET_BUY_BAR,
    payload: value,
  };
};

export const SET_CONNECT_BAR = "SET_CONNECT_BAR";
export const setConnectBar = (value) => {
  return {
    type: SET_CONNECT_BAR,
    payload: value,
  };
};

export const SET_CONNECT_BAR_LOADING = "SET_CONNECT_BAR_LOADING";
export const setConnectBarLoading = (value) => {
  return {
    type: SET_CONNECT_BAR_LOADING,
    payload: value,
  };
};

export const SET_PASSPORT_BAR = "SET_PASSPORT_BAR";
export const setPassportBar = (value) => {
  return {
    type: SET_PASSPORT_BAR,
    payload: value,
  };
};
