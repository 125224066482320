import { config, x, passport } from "@imtbl/sdk";
import { API_CONFIG } from "configs/api.config";
import pathes from "configs/pathes";

const { Environment } = config;
const { IMXClient, imxClientConfig } = x;

const environment =
  process.env.REACT_APP_ENV === "development"
    ? Environment.SANDBOX
    : Environment.PRODUCTION;

export const passportInstance = new passport.Passport({
  baseConfig: {
    environment,
    publishableKey: process.env.REACT_APP_IMX_PUBLISHABLE_KEY,
  },
  clientId: process.env.REACT_APP_IMX_CLIENT_ID,
  redirectUri: `${API_CONFIG.DOMAIN}${pathes.IMX_REDIRECT}`, // replace with one of your redirect URIs from Hub
  logoutRedirectUri: `${API_CONFIG.DOMAIN}${pathes.IMX_LOGOUT}`, // replace with one of your logout URIs from Hub
  logoutMode: "silent",
  audience: "platform_api",
  scope: "openid offline_access email transact",
  popupOverlayOptions: {
    disableGenericPopupOverlay: false, // Set to true to disable the generic pop-up overlay
    disableBlockedPopupOverlay: false, // Set to true to disable the blocked pop-up overlay
  },
});

export const imxClient = new IMXClient(imxClientConfig({ environment }));
