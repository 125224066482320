export const SET_SIDE_BAR = "SET_TITLE_DATE";
export const setSideBar = (value) => {
  return {
    type: SET_SIDE_BAR,
    payload: value,
  };
};

export const SET_SYNC_MODAL = "SET_SYNC_MODAL";
export const setSyncModal = (value) => {
  return {
    type: SET_SYNC_MODAL,
    payload: value,
  };
};
