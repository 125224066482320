import { ENDPOINTS } from "configs/endpoints.config";

import { API_CONFIG } from "configs/api.config";

const { GET_LEADERS } = ENDPOINTS;
const { URL } = API_CONFIG;

export const LEADERBOARD = "LEADERBOARD";
export const getLeaders = () => {
  return {
    type: LEADERBOARD,
    request: {
      url: `${URL}${GET_LEADERS}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};
