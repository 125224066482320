export const ENDPOINTS = {
  SIGN_IN: "/auth/login/general",
  REGISTER: "/auth/register",
  USER_GET: "/auth/me",
  LOG_WITH_WALLET: "/auth/login/wallet",
  VERIFING_WALLET: "/auth/verify/wallet",
  CHECK_IS_WALLET_EXIST: "/auth/check/wallet",
  GET_AVATAR: "/avatars",
  POST_AVATAR: "/auth/avatar",
  GET_STATISTICS: "/rank/statistics",
  GET_LEADERS: "/rank/leaderboard",
  EMAIL_CH_CONF: "/auth/change-email",
  CHANGE_PASSWORD: "/auth/change-password",
  NEWS_LETTER: "/newsletter",
  TIME_GET: "/gateway/time_to_refresh",
  BRIDGE: "/bridge",
  GET_ASSET_COUNT: "/asset/get-count-by-type",
  GET_MY_ASSET_LIST: "/asset/my-list",
  GET_RPG_PRICE: "/gateway/get-info",
  GET_RPG_PRICES_HISTORY: "/gateway/get-prices",
};
