import { LEADERBOARD } from "./actions";
import { success, error } from "redux-saga-requests";
import { STATE_STATUSES } from "constants/statuses.constants";
import { errorReducer, processReducer } from "utils/storage.statuses";

const initialState = {
  users: [],
  leaderboard: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LEADERBOARD: {
      return processReducer(state);
    }
    case success(LEADERBOARD): {
      return {
        ...state,
        leaderboard: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case error(LEADERBOARD): {
      return errorReducer(state);
    }

    default:
      return state;
  }
};
