import React from 'react';
import styles from "./Fallback.module.scss";
import { IMAGES } from "assets/images";

const Fallback = () => {
  return (
    <div className={styles.wrapper}>
       <div
          className={styles.logo}
          style={{
            backgroundImage: `url(${IMAGES.whiteLogo})`,
          }}
        ></div>
        <div className={styles.text}>loading...</div>
    </div>
  );
}

export default Fallback;
