import mainLogo from "./mainLogo.svg";
import gameLogo from "./gameLogo.svg";
import gameLogoW from "./gameLogoW.svg";
import generateLogo from "./generateLogo.svg";
import generateLogoW from "./generateLogoW.svg";
import liquidityLogo from "./liquidityLogo.svg";
import liquidityLogoW from "./liquidityLogoW.svg";
import nftLogo from "./nftLogo.svg";
import nftLogoW from "./nftLogoW.svg";
import overviewLogo from "./overviewLogo.svg";
import overviewLogoW from "./overviewLogoW.svg";
// import userLogo from './userLogo.svg'
import userLogo from "./userLogo.webp";
import bigLogo from "./bigLogo.svg";
import lockLogo from "./lockLogo.svg";
import envelopeLogo from "./envelopeLogo.svg";
// import photoLogo from './photoLogo.svg'
import menuDots from "./menuDots.svg";
import changeEmailLogo from "./changeEmailLogo.svg";
import changePassLogo from "./changePassLogo.svg";
import logOutLogo from "./logOutLogo.svg";
import clipboardLogo from "./clipboardLogo.svg";
import pancakeLogo from "./pancakeLogo.svg";
import uniswapLogo from "./uniswapLogo.svg";
import arrow from "./arrow.svg";
import gamePoster from "./gamePoster.webp";
import gamePoster2 from "./gamePoster.svg";
import photoLogo from "./photoLogo.svg";
import maxBtnBottom from "./maxBtnBottom.svg";
import randomUser from "./randomUser.webp";
import leftTri from "./leftTri.svg";
import headerLogoBckgrd from "./headerLogoBckgrd.svg";
import sideBarLine from "./sideBarLine.svg";
import sideBarCurrent from "./sideBarCurrent.svg";
import analyticsLogo from "./analyticsLogo.svg";
import analyticsLogoW from "./analyticsLogoW.svg";
import checkPoint from "./checkPoint.svg";
import canceled from "./canceled.svg";
import checked from "./checked.svg";
import sandClock from "./sandClock.svg";
import g1poster from "./g1poster.webp";
import g2poster from "./g2poster.webp";
import invLogo from "./invLogo.svg";
import invLogoW from "./invLogoW.svg";
import invBuild from "./invBuild.svg";
import invChar from "./invChar.svg";
import invGates from "./invGates.svg";
import invVech from "./invVech.svg";
import invSelect from "./invSelect.svg";
import invLine from "./invLine.webp";
import invUp from "./invUp.svg";
import invDown from "./invDown.svg";
import invForward from "./invForward.svg";
import invBack from "./invBack.svg";
import gaChar from "./gaChar.webp";
import gaGb from "./gaGb.webp";
import gaSelect1 from "./gaSelect1.svg";
import gaSelect2down from "./gaSelect2down.svg";
import gaSelect2up from "./gaSelect2up.svg";
import gaSg from "./gaSg.webp";
import gaVech from "./gaVech.webp";
import gaWarn from "./gaWarn.webp";
import pencil from "./pencil.webp";
import progressRarity from "./progressRarity.svg";
import tokenIcon from "./tokenIcon.svg";
import metamask from "./metamask.svg";
import trustwallet from "./trustwallet.svg";
import walletconnect from "./walletconnect.svg";
import extLink from "./extLink.webp";
import bossMonkey from "./bossMonkey.webp";
import bridgePopCross from "./bridgePopCross.webp";
import quickSwapLogo2 from "./quickSwapLogo2.webp";
import whiteLogo from "./whiteLogo.svg";
import spiner from "./spiner.svg";
import circle from "./circle.svg";
import trilqarLogo from "./trilqarLogo.webp";
import desinLogo from "./desinLogo.webp";
import agonLogo from "./agonLogo.webp";
import elips1 from "./elips1.webp";
import elips2 from "./elips2.webp";
import BBPoster from "./BBPoster.webp";
import bnbSing from "./bnbSing.png";
import rpgSing from "./rpgSing.png";
import rpgPan from "./rpgPan.png";
import sing from "./sing.png";
import bnbLogo from "./bnbLogo.svg";
import rpgLogo from "./rpgLogo.png";
import backArrow from "./backArrow.svg";
import rpgBnbLp from "./rpgBnbLp.png";
import warning from "./warning.svg";
import bscLogo from "./bscLogo.svg";
import imxLogo from "./imxLogo.webp";
import passportIcon from "./passportIcon.svg";

export const IMAGES = {
  mainLogo: mainLogo,
  gameLogo: gameLogo,
  gameLogoW: gameLogoW,
  generateLogo: generateLogo,
  generateLogoW: generateLogoW,
  liquidityLogo: liquidityLogo,
  liquidityLogoW: liquidityLogoW,
  nftLogo: nftLogo,
  nftLogoW: nftLogoW,
  invLogo: invLogo,
  invLogoW: invLogoW,
  overviewLogo: overviewLogo,
  overviewLogoW: overviewLogoW,
  userLogo: userLogo,
  bigLogo: bigLogo,
  lockLogo: lockLogo,
  envelopeLogo: envelopeLogo,
  photoLogo: photoLogo,
  menuDots: menuDots,
  changeEmailLogo: changeEmailLogo,
  changePassLogo: changePassLogo,
  logOutLogo: logOutLogo,
  clipboardLogo: clipboardLogo,
  pancakeLogo: pancakeLogo,
  uniswapLogo: uniswapLogo,
  arrow: arrow,
  whiteLogo: whiteLogo,
  gamePoster: gamePoster,
  gamePoster2: gamePoster2,
  maxBtnBottom: maxBtnBottom,
  randomUser: randomUser,
  leftTri: leftTri,
  headerLogoBckgrd: headerLogoBckgrd,
  sideBarLine: sideBarLine,
  sideBarCurrent: sideBarCurrent,
  analyticsLogo: analyticsLogo,
  analyticsLogoW: analyticsLogoW,
  checkPoint: checkPoint,
  canceled: canceled,
  checked: checked,
  sandClock: sandClock,
  g1poster: g1poster,
  g2poster: g2poster,
  invBuild: invBuild,
  invChar: invChar,
  invGates: invGates,
  invVech: invVech,
  invSelect: invSelect,
  invLine: invLine,
  invUp: invUp,
  invDown: invDown,
  invBack: invBack,
  invForward: invForward,
  gaChar: gaChar,
  gaGb: gaGb,
  gaSelect1: gaSelect1,
  gaSelect2down: gaSelect2down,
  gaSelect2up: gaSelect2up,
  gaSg: gaSg,
  gaVech: gaVech,
  gaWarn: gaWarn,
  pencil: pencil,
  progressRarity: progressRarity,
  tokenIcon: tokenIcon,
  metamask: metamask,
  trustwallet: trustwallet,
  walletconnect: walletconnect,
  extLink: extLink,
  bridgePopCross: bridgePopCross,
  quickSwapLogo2: quickSwapLogo2,
  spiner: spiner,
  circle: circle,
  trilqarLogo: trilqarLogo,
  desinLogo: desinLogo,
  agonLogo: agonLogo,
  elips1: elips1,
  elips2: elips2,
  BBPoster: BBPoster,
  bossMonkey: bossMonkey,
  bnbSing: bnbSing,
  rpgSing: rpgSing,
  rpgPan: rpgPan,
  sing: sing,
  bnbLogo: bnbLogo,
  rpgLogo: rpgLogo,
  backArrow: backArrow,
  rpgBnbLp: rpgBnbLp,
  warning: warning,
  bscLogo: bscLogo,
  imxLogo: imxLogo,
  passportIcon: passportIcon,
};
