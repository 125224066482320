import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import Modals from "Modals";
import "antd/dist/antd.css";
import "./i18n/i18n";
import { Navigation } from "navigation";
import store from "store";
import TagManager from "react-gtm-module";
import { Web3Provider, UpdatersProvider, ImxProvider } from "providers";
import { useDispatch } from "react-redux";
import { setSingularityMounted } from "store/utils/actions";
import Fallback from "components/Fallback";
import "react-notifications/lib/notifications.css";
import "react-responsive-modal/styles.css";
import { NotificationContainer } from "react-notifications";

const tagManagerArgs = {
  gtmId: "GTM-P4Z24DB",
};
TagManager.initialize(tagManagerArgs);

const SingularityProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.body.addEventListener("Singularity-mounted", () => {
      const key = "OUFOHLrO3o4wMx9kw94pLLl9no91Fh1r";
      window.Singularity.init(key, () => {
        dispatch(setSingularityMounted(true));
      });
    });
  }, []);

  return <>{children}</>;
};

const App = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Provider store={store}>
        <Web3Provider>
          <ImxProvider>
            <SingularityProvider>
              <UpdatersProvider>
                <Modals />
                <Navigation />
              </UpdatersProvider>
              <NotificationContainer />
            </SingularityProvider>
          </ImxProvider>
        </Web3Provider>
      </Provider>
    </Suspense>
  );
};

export default App;
