import React from "react";
import BuyModal from "components/BuyModal";
import ConnectModal from "components/ConnectModal";
import PassportModal from "components/PassportModal";

const Modals = () => {
  return (
    <>
      <BuyModal />
      <ConnectModal />
      <PassportModal />
    </>
  );
};

export default Modals;
