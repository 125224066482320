import {
  LOGIN,
  SIGN_UP,
  GET_USER,
  CHECK_WALLET,
  LOG_OUT,
  SIGN_UP_W,
  VERIFY_WALLET,
  UPLOAD_AVATAR,
  CHANGE_EMAIL,
  CHANGE_PASS,
} from "./actions";
import { success, error } from "redux-saga-requests";
import { STATE_STATUSES } from "constants/statuses.constants";

import { CONSTANTS } from "constants/global.constants";
import { errorReducer, processReducer } from "utils/storage.statuses";
const { AUTH_TOKEN, CURRENT_USER_ID } = CONSTANTS;

const initialState = {
  token: localStorage.getItem(AUTH_TOKEN)
    ? JSON.parse(localStorage.getItem(AUTH_TOKEN))
    : "",
  current_user_id: localStorage.getItem(CURRENT_USER_ID)
    ? JSON.parse(localStorage.getItem(CURRENT_USER_ID))
    : undefined,
  user: {},
  checkWalletExist: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return processReducer(state);
    }
    case success(LOGIN): {
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        status: STATE_STATUSES.READY,
      };
    }
    case error(LOGIN): {
      return errorReducer(state);
    }

    case LOG_OUT: {
      return {
        ...state,
        token: "",
        status: STATE_STATUSES.READY,
      };
    }
    case success(LOG_OUT): {
      return {
        ...initialState,
        status: STATE_STATUSES.READY,
      };
    }
    case error(LOG_OUT): {
      return errorReducer(state);
    }

    case SIGN_UP: {
      return processReducer(state);
    }
    case success(SIGN_UP): {
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        status: STATE_STATUSES.READY,
      };
    }
    case error(SIGN_UP): {
      return errorReducer(state);
    }

    case CHANGE_EMAIL: {
      return processReducer(state);
    }
    case success(CHANGE_EMAIL): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(CHANGE_EMAIL): {
      return errorReducer(state);
    }

    case CHANGE_PASS: {
      return processReducer(state);
    }
    case success(CHANGE_PASS): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(CHANGE_PASS): {
      return errorReducer(state);
    }

    case GET_USER: {
      return processReducer(state);
    }
    case success(GET_USER): {
      return {
        ...state,
        user: action.data.user,
        status: STATE_STATUSES.READY,
      };
    }
    case error(GET_USER): {
      return errorReducer(state);
    }

    case SIGN_UP_W: {
      return processReducer(state);
    }
    case success(SIGN_UP_W): {
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        status: STATE_STATUSES.READY,
      };
    }
    case error(SIGN_UP_W): {
      return errorReducer(state);
    }

    case CHECK_WALLET: {
      return processReducer(state);
    }
    case success(CHECK_WALLET): {
      return {
        ...state,
        checkWalletExist: action.data.exists,
        status: STATE_STATUSES.READY,
      };
    }
    case error(CHECK_WALLET): {
      return errorReducer(state);
    }

    case VERIFY_WALLET: {
      return processReducer(state);
    }
    case success(VERIFY_WALLET): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(VERIFY_WALLET): {
      return errorReducer(state);
    }

    case UPLOAD_AVATAR: {
      return processReducer(state);
    }
    case success(UPLOAD_AVATAR): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(UPLOAD_AVATAR): {
      return errorReducer(state);
    }

    default:
      return state;
  }
};
