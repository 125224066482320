import { useDispatch } from "react-redux";
import useSWR from "swr";
import BigNumber from "bignumber.js";
import { multicall } from "@wagmi/core";
import { getPrice } from "store/configs/actions";
import { bsc } from "wagmi/chains";
import lpAbi from "contracts/pair";

const lpPairContract = {
  address: "0xa9c815b975b72172d220065319902b176c527d72",
  abi: lpAbi,
  chainId: bsc.id,
};

const INTERVAL_TIMER = 60000;

export const useUpdatePrices = () => {
  const dispatch = useDispatch();

  const { isLoading, isValidating, mutate } = useSWR(
    `/getPricesData`,
    async () => {
      return getPricesData();
    },
    { revalidateOnFocus: false, refreshInterval: INTERVAL_TIMER }
  );
  const getPricesData = async () => {
    const reservesData = await multicall({
      chainId: bsc.id,
      contracts: [
        {
          ...lpPairContract,
          functionName: "getReserves",
        },
      ],
    });

    if (reservesData && reservesData[0].status === "success") {
      const rpgPrice = Number(
        new BigNumber(reservesData[0].result[1])
          .dividedBy(new BigNumber(reservesData[0].result[0]))
          .toString()
      );
      dispatch(getPrice({ price: rpgPrice, stamp: new Date() }));
    } else {
      dispatch(getPrice({ price: 0, stamp: new Date() }));
    }
  };

  return {
    isLoading: isLoading || isValidating,
    updatePricesData: mutate,
  };
};
