const PATHES = {
  MAIN: "/",
  LIQUIDITY_STAKING: "/liquidityStaking",
  NFT_GENERATE: "/nft-generate",
  NFT_MARKET: "/nft-market",
  INVENTORY: "/inventory",
  GAME: "/game",
  USER_PROFILE: "/user-profile/",
  ANALYTICS: "/analytics", //Change when page will be complite
  GAME_ONE: "/games/1",
  GAME_TWO: "/games/2",
  CHANGE_EMAIL: "/update-email/:param",
  NEWSLETTER_CONF: "/newsletter-confirm/:param",
  BRIDGE: "/revolve-bridge",
  BB_BETA: "/bones-and-blade-beta",
  IMX_START: "/imx-start",
  IMX_REDIRECT: "/redirect-imx",
  IMX_LOGOUT: "/logout-imx",
};

export default PATHES;
