import { ENDPOINTS } from "configs/endpoints.config";
import { API_CONFIG } from "configs/api.config";
const { EMAIL_CH_CONF, NEWS_LETTER, TIME_GET } = ENDPOINTS;
const { URL } = API_CONFIG;

export const GET_TIME = "GET_TIME";
export const getTime = () => {
  return {
    type: GET_TIME,
    request: {
      url: `${URL}${TIME_GET}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};

export const GET_PRICE = "GET_PRICE";
export const getPrice = (value) => {
  return {
    type: GET_PRICE,
    payload: value,
  };
};

export const CHAIN_CHANGE = "CHAIN_CHANGE";
export const setChain = (value) => {
  return {
    type: CHAIN_CHANGE,
    payload: value,
  };
};

export const UNVESTED = "UNVESTED";
export const getUnvest = (value) => {
  return {
    type: UNVESTED,
    payload: value,
    meta: {
      asPromise: true,
    },
  };
};

export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const confirmEmail = (data) => {
  return {
    type: CONFIRM_EMAIL,
    request: {
      url: `${URL}${EMAIL_CH_CONF}/${data}`,
      method: "POST",
    },
    meta: {
      asPromise: true,
    },
  };
};

export const NEWSLTR = "NEWSLTR";
export const newsletter = (data) => {
  return {
    type: NEWSLTR,
    request: {
      url: `${URL}${NEWS_LETTER}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const CONFIRM_NEWSLETTER = "CONFIRM_NEWSLETTER";
export const confirmNewsletter = (data) => {
  return {
    type: CONFIRM_NEWSLETTER,
    request: {
      url: `${URL}${NEWS_LETTER}/${data}`,
      method: "POST",
    },
    meta: {
      asPromise: true,
    },
  };
};
