import { SET_SIDE_BAR, SET_SYNC_MODAL } from "./actions";

const initialState = {
  isOpenedSidebar: false,
  isOpenedSyncModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDE_BAR: {
      return {
        ...state,
        isOpenedSidebar: action.payload,
      };
    }

    case SET_SYNC_MODAL: {
      return {
        ...state,
        isOpenedSyncModal: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
