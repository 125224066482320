import React, { lazy, Suspense } from "react";
import Fallback from "components/Fallback";

export const lazyLoad = (
  importFunc,
  selectorFunc,
  opts = { fallback: <Fallback /> }
) => {
  let lazyFactory = importFunc;

  if (selectorFunc) {
    lazyFactory = () =>
      importFunc().then((module) => ({ default: selectorFunc(module) }));
  }

  const LazyComponent = lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(lazyFactory());
        }, opts.delay ?? 0);
      })
  );

  const LazyLoader = (props) => {
    return (
      <Suspense fallback={opts.fallback}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };

  return LazyLoader;
};
