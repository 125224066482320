import { useEffect, useState } from "react";

export const useTextLoader = (isLoading = true) => {
  const [loader, setLoader] = useState("...");

  useEffect(() => {
    if (!isLoading) {
      setLoader("");
      return;
    }

    const loaderStates = ["...", ".", ".."];
    let currentLoaderIndex = 0;

    const intervalId = setInterval(() => {
      currentLoaderIndex = (currentLoaderIndex + 1) % loaderStates.length;
      setLoader(loaderStates[currentLoaderIndex]);
    }, 500);

    return () => clearInterval(intervalId);
  }, [isLoading]);

  return loader;
};

export default useTextLoader;
