import { ENDPOINTS } from "configs/endpoints.config";
import { API_CONFIG } from "configs/api.config";
const { BRIDGE } = ENDPOINTS;
const { URL } = API_CONFIG;

export const GET_BRIDGE_INFO = "GET_BRIDGE_INFO";
export const getBridgeInfo = () => {
  return {
    type: GET_BRIDGE_INFO,
    request: {
      url: `${URL}${BRIDGE}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};
