import React from "react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { Modal } from "react-responsive-modal";
import { IMAGES } from "assets/images";
import styles from "./ConnectModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setConnectBar } from "store/modals/actions";
import ClipLoader from "react-spinners/ClipLoader";
import { useImx } from "hooks";

const ConnectModal = () => {
  const { isOpenedConnect: get, isConnectLoading: loading } = useSelector(
    (state) => state.modals
  );
  const dispatch = useDispatch();
  const { openConnectModal } = useConnectModal();
  const { loginImx } = useImx();

  const onCloseModal = () => {
    dispatch(setConnectBar(false));
  };

  const connectWithWeb3 = async () => {
    dispatch(setConnectBar(false));
    openConnectModal();
  };

  const connectWithImx = async () => {
    dispatch(setConnectBar(false));
    loginImx();
  };

  const zeroStageOptions = [
    {
      title: "Immutable Passport",
      logo: IMAGES.passportIcon,
      func: connectWithImx,
    },
    {
      title: "Web3 Wallet",
      logo: IMAGES.walletconnect,
      func: connectWithWeb3,
    },
  ];

  const zeroStageOptionsCreator = () => {
    return zeroStageOptions.map((option, index) => {
      return (
        <div
          className={
            loading ? styles.buyOptionWrapperBlocked : styles.buyOptionWrapper
          }
          onClick={() => (loading ? null : option.func())}
          key={index}
        >
          {loading ? (
            <ClipLoader
              color={"#ffffff"}
              loading={true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <img src={option.logo} alt="" className={styles.platformLogo} />
          )}
          <div className={styles.optionText}>{option.title}</div>
        </div>
      );
    });
  };

  return (
    <Modal
      open={get}
      showCloseIcon={false}
      onClose={onCloseModal}
      center
      animationDuration={0}
      classNames={{
        modal: styles.icwModal,
      }}
    >
      <div className={styles.fromsWrapper}>
        <div className={styles.wrapper}>
          <h1 className={styles.header}>CONNECT WITH</h1>
          <div className={styles.buyOptionsWrapper}>
            {zeroStageOptionsCreator()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectModal;
