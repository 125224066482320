import { injectedWallet } from "@rainbow-me/rainbowkit/wallets";

export const wombatWallet = ({ chains }) => ({
  id: "wombat-wallet",
  name: "Wombat",
  iconUrl:
    "https://static.debank.com/image/matic_token/logo_url/0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd/22722d53ee834a999e26b17171e13d84.png",
  iconBackground: "#FF4E24",

  downloadUrls: {
    android:
      "https://play.google.com/store/apps/details?id=io.getwombat.android&pli=1",
    ios: "https://apps.apple.com/de/app/wombat-home-of-nft-gaming/id1474392110",
    chrome:
      "https://chromewebstore.google.com/detail/wombat-gaming-wallet-for/amkmjjmmflddogmhpjloimipbofnfjih?pli=1",
  },

  createConnector: injectedWallet({ chains }).createConnector,
});
