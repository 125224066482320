import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { MainOutlet } from "components/outlets";
import { PrivateRoute } from "./components/PrivateRoute";
import pathes from "configs/pathes";

const MainPage = React.lazy(() => import("pages/main"));
const LiquidityStakingPage = React.lazy(() => import("pages/liquidityStaking"));
const UserProfilePage = React.lazy(() => import("pages/userProfile"));
const NftMarketplacePage = React.lazy(() => import("pages/nftMarketplace"));
const GenerateNftPage = React.lazy(() => import("pages/generateNft"));
const GamePage = React.lazy(() => import("pages/game"));
const AnalyticsPage = React.lazy(() => import("pages/analytics"));
const InventoryPage = React.lazy(() => import("pages/inventory"));
const SomeGame = React.lazy(() => import("pages/game/someGame"));
const EmailChange = React.lazy(() => import("pages/emailChange"));
const Newsletters = React.lazy(() => import("pages/newsletters"));
const BbBetaPage = React.lazy(() => import("pages/bbBeta"));
const ImxTemplate = React.lazy(() => import("pages/imx"));

export const Navigation = () => {
  return (
    <Routes>
      <Route path={pathes.MAIN} element={<MainOutlet />}>
        <Route path={pathes.MAIN} element={<MainPage />} />
        <Route path={pathes.ANALYTICS} element={<AnalyticsPage />} />
        <Route path={pathes.GAME} element={<GamePage />} />
        <Route path={pathes.NFT_MARKET} element={<NftMarketplacePage />} />
        <Route
          path={pathes.USER_PROFILE}
          element={
            <PrivateRoute>
              <UserProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path={pathes.LIQUIDITY_STAKING}
          element={<LiquidityStakingPage />}
        />
        <Route
          path={pathes.NFT_GENERATE}
          element={
            <PrivateRoute>
              <GenerateNftPage />
            </PrivateRoute>
          }
        />
        <Route
          path={pathes.INVENTORY}
          element={
            <PrivateRoute>
              <InventoryPage />
            </PrivateRoute>
          }
        />
        <Route path={pathes.GAME_ONE} element={<SomeGame />} />
        <Route path={pathes.GAME_TWO} element={<SomeGame />} />
        <Route path={pathes.CHANGE_EMAIL} element={<EmailChange />} />
        <Route path={pathes.NEWSLETTER_CONF} element={<Newsletters />} />
        <Route path={pathes.BB_BETA} element={<BbBetaPage />} />
      </Route>
      <Route path={pathes.IMX_START} element={<ImxTemplate />} />
      <Route path={pathes.IMX_REDIRECT} element={<ImxTemplate />} />
      <Route path={pathes.IMX_LOGOUT} element={<ImxTemplate />} />
      <Route path="*" element={<Navigate replace to={pathes.MAIN} />} />
    </Routes>
  );
};
