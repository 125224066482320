import { ENDPOINTS } from "configs/endpoints.config";
import { CONSTANTS } from "constants/global.constants";

import { API_CONFIG } from "configs/api.config";

const {
  SIGN_IN,
  REGISTER,
  USER_GET,
  LOG_WITH_WALLET,
  VERIFING_WALLET,
  POST_AVATAR,
  CHECK_IS_WALLET_EXIST,
  EMAIL_CH_CONF,
  CHANGE_PASSWORD,
} = ENDPOINTS;
const { URL } = API_CONFIG;
const { AUTH_TOKEN, CURRENT_USER_ID } = CONSTANTS;

export const LOGIN = "LOGIN";
const logIn = (data) => {
  return {
    type: LOGIN,
    request: {
      url: `${URL}${SIGN_IN}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const requestLogIn = (data) => async (dispatch) => {
  await dispatch(logIn(data)).then((response) => {
    const {
      token,
      user: { id },
    } = response.data;
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    localStorage.setItem(CURRENT_USER_ID, JSON.stringify(id));
  });
};

export const SIGN_UP = "SIGN_UP";
export const signUp = (data) => {
  return {
    type: SIGN_UP,
    request: {
      url: `${URL}${REGISTER}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const requestSignUp = (data) => async (dispatch) => {
  await dispatch(signUp(data)).then((response) => {
    const {
      token,
      user: { id },
    } = response.data;
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    localStorage.setItem(CURRENT_USER_ID, JSON.stringify(id));
  });
};

export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const changeEmail = (data) => {
  return {
    type: CHANGE_EMAIL,
    request: {
      url: `${URL}${EMAIL_CH_CONF}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const CHANGE_PASS = "CHANGE_PASS";
export const changePass = (data) => {
  return {
    type: CHANGE_PASS,
    request: {
      url: `${URL}${CHANGE_PASSWORD}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const CHECK_WALLET = "CHECK_WALLET";
export const checkWallet = (data) => {
  return {
    type: CHECK_WALLET,
    request: {
      url: `${URL}${CHECK_IS_WALLET_EXIST}/{address}?address=${data}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};

export const SIGN_UP_W = "SIGN_UP_W";
export const signUpW = (data) => {
  return {
    type: SIGN_UP_W,
    request: {
      url: `${URL}${LOG_WITH_WALLET}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const requestSignUpW = (data) => async (dispatch) => {
  await dispatch(signUpW(data)).then((response) => {
    const {
      token,
      user: { id },
    } = response.data;
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    localStorage.setItem(CURRENT_USER_ID, JSON.stringify(id));
  });
};

export const GET_USER = "GET_USER";
export const getUser = () => {
  return {
    type: GET_USER,
    request: {
      url: `${URL}${USER_GET}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};

export const LOG_OUT = "LOG_OUT";
export const logout = () => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(""));
  localStorage.setItem(CURRENT_USER_ID, JSON.stringify(""));
  return {
    type: LOG_OUT,
  };
};

export const VERIFY_WALLET = "VERIFY_WALLET";
export const verifyWallet = (data) => {
  return {
    type: VERIFY_WALLET,
    request: {
      url: `${URL}${VERIFING_WALLET}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const uploadAvatar = (data) => {
  return {
    type: UPLOAD_AVATAR,
    request: {
      url: `${URL}${POST_AVATAR}`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};
