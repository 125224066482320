import {
  SET_BUY_BAR,
  SET_CONNECT_BAR,
  SET_CONNECT_BAR_LOADING,
  SET_PASSPORT_BAR,
} from "./actions";

const initialState = {
  isOpenedBuybar: false,
  isOpenedConnect: false,
  isConnectLoading: false,
  isOpenedPassport: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUY_BAR: {
      return {
        ...state,
        isOpenedBuybar: action.payload,
      };
    }

    case SET_CONNECT_BAR: {
      return {
        ...state,
        isOpenedConnect: action.payload,
      };
    }

    case SET_CONNECT_BAR_LOADING: {
      return {
        ...state,
        isConnectLoading: action.payload,
      };
    }

    case SET_PASSPORT_BAR: {
      return {
        ...state,
        isOpenedPassport: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
