import {
  CONFIRM_EMAIL,
  UNVESTED,
  GET_TIME,
  CHAIN_CHANGE,
  NEWSLTR,
  CONFIRM_NEWSLETTER,
  GET_PRICE,
} from "./actions";
import { success, error } from "redux-saga-requests";
import { STATE_STATUSES } from "constants/statuses.constants";
import { errorReducer, processReducer } from "utils/storage.statuses";

const initialState = {
  serverTime: {},
  price: 0,
  stamp: 0,
  unvestSum: 0,
  chainId: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TIME: {
      return processReducer(state);
    }
    case success(GET_TIME): {
      return {
        ...state,
        serverTime: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case error(GET_TIME): {
      return errorReducer(state);
    }

    case GET_PRICE: {
      return {
        ...state,
        price: +action.payload.price,
        stamp: action.payload.stamp,
        status: STATE_STATUSES.READY,
      };
    }

    case CHAIN_CHANGE: {
      return {
        ...state,
        chainId: action.payload,
        status: STATE_STATUSES.READY,
      };
    }

    case UNVESTED: {
      return processReducer(state);
    }
    case success(UNVESTED): {
      return {
        ...state,
        unvestSum: action.payload,
        status: STATE_STATUSES.READY,
      };
    }
    case error(UNVESTED): {
      return errorReducer(state);
    }

    case CONFIRM_EMAIL: {
      return processReducer(state);
    }
    case success(CONFIRM_EMAIL): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(CONFIRM_EMAIL): {
      return errorReducer(state);
    }

    case NEWSLTR: {
      return processReducer(state);
    }
    case success(NEWSLTR): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(NEWSLTR): {
      return errorReducer(state);
    }

    case CONFIRM_NEWSLETTER: {
      return processReducer(state);
    }
    case success(CONFIRM_NEWSLETTER): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(CONFIRM_NEWSLETTER): {
      return errorReducer(state);
    }

    default:
      return state;
  }
};
