export const CONNECTION_TYPE = "CONNECTION_TYPE";
export const SET_CONNECTION_TYPE = "SET_CONNECTION_TYPE";
export const setConnectionType = (value) => {
  if (value) {
    localStorage.setItem(CONNECTION_TYPE, JSON.stringify(value));
  } else {
    localStorage.removeItem(CONNECTION_TYPE);
  }
  return {
    type: SET_CONNECTION_TYPE,
    payload: value,
  };
};

export const SET_CONNECTION_LOADING = "SET_CONNECTION_LOADING";
export const setConnectionLoading = (value) => {
  return {
    type: SET_CONNECTION_LOADING,
    payload: value,
  };
};

export const SET_IMX_USER = "SET_IMX_USER";
export const setImxUser = (value) => {
  return {
    type: SET_IMX_USER,
    payload: value,
  };
};
