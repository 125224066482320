import { GET_BRIDGE_INFO } from "./actions";
import { success, error } from "redux-saga-requests";
import { STATE_STATUSES } from "constants/statuses.constants";
import { errorReducer, processReducer } from "utils/storage.statuses";

const initialState = { bridgeInfo: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BRIDGE_INFO: {
      return processReducer(state);
    }
    case success(GET_BRIDGE_INFO): {
      return {
        ...state,
        bridgeInfo: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case error(GET_BRIDGE_INFO): {
      return errorReducer(state);
    }

    default:
      return state;
  }
};
