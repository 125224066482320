export const API_CONFIG = {
  URL: process.env.REACT_APP_API_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  WIDGET: process.env.REACT_APP_WIDGET_URL,
  DOMAIN: process.env.REACT_APP_URL,
};

// http://35.176.157.239/widget
// https://revolvegames-dev.idealogic.dev/widget
// https://revolvegames.io/widget
