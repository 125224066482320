import {
  SET_CONNECTION_TYPE,
  SET_CONNECTION_LOADING,
  SET_IMX_USER,
  CONNECTION_TYPE,
} from "./actions";

const initialState = {
  connectionType: localStorage.getItem(CONNECTION_TYPE)
    ? JSON.parse(localStorage.getItem(CONNECTION_TYPE))
    : undefined,
  connectionLoading: false,
  imxUser: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONNECTION_TYPE: {
      return {
        ...state,
        connectionType: action.payload,
      };
    }

    case SET_CONNECTION_LOADING: {
      return {
        ...state,
        connectionLoading: action.payload,
      };
    }

    case SET_IMX_USER: {
      return {
        ...state,
        imxUser: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
