import { GET_STATS, GET_LEADER } from "./actions";
import { success, error } from "redux-saga-requests";
import { STATE_STATUSES } from "constants/statuses.constants";
import { errorReducer, processReducer } from "utils/storage.statuses";

const initialState = {
  mainStats: { activePlayers: "-", topScore: "-", averageScore: "-" },
  leaderBoard: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATS: {
      return processReducer(state);
    }
    case success(GET_STATS): {
      return {
        ...state,
        mainStats: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case error(GET_STATS): {
      return errorReducer(state);
    }

    case GET_LEADER: {
      return processReducer(state);
    }
    case success(GET_LEADER): {
      return {
        ...state,
        leaderBoard: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case error(GET_LEADER): {
      return errorReducer(state);
    }

    default:
      return state;
  }
};
