import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { useWalletClient } from "wagmi";

export const useSingularityConnect = () => {
  const { token } = useSelector((state) => state.auth);
  const { address } = useAccount();
  const { data: walletClient, isError, isLoading } = useWalletClient();

  const singularityConnect = async () => {
    if (
      token &&
      !isError &&
      !isLoading &&
      window &&
      window?.SingularityEvent &&
      window?.SingularityEvent?.loginWithProvider
    ) {
      await window.SingularityEvent.loginWithProvider(walletClient);
    }
  };

  useEffect(() => {
    singularityConnect();
  }, [address, walletClient, isLoading, token]);
};
