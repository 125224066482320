import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { IMAGES } from "assets/images";
import styles from "./PassportModal.module.scss";
import { passportInstance } from "utils/imx";
import { setConnectionType } from "store/vars/actions";
import { logout } from "store/auth/actions";
import { useSelector, useDispatch } from "react-redux";
import { setPassportBar } from "store/modals/actions";

const PassportModal = () => {
  const isOpenedPassport = useSelector(
    (state) => state.modals.isOpenedPassport
  );
  const { imxUser } = useSelector((state) => state.vars);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    dispatch(setPassportBar(false));
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await passportInstance.logout();
      dispatch(setConnectionType(undefined));
      dispatch(logout());
    } catch (error) {
      console.error("Error logging out: ", error);
    }
    dispatch(setPassportBar(false));
    setLoading(false);
  };

  if (!isOpenedPassport) {
    return null;
  }

  return (
    <Modal
      open={isOpenedPassport}
      showCloseIcon={false}
      onClose={onCloseModal}
      center
      animationDuration={0}
      classNames={{
        modal: styles.icwModal,
      }}
    >
      <div className={styles.fromsWrapper}>
        <div className={styles.wrapper}>
          <h1 className={styles.header}>IMMUTABLE PASSPORT</h1>
          <div className={styles.verticalWrapper}>
            <div className={styles.horizontalWrapper}>
              <img
                src={IMAGES.passportIcon}
                className={styles.passportIcon}
              ></img>
              <div className={styles.passportData}>
                <p className={styles.passportItem}>
                  {"Email: "}
                  <span className={styles.passportItemData}>
                    {imxUser?.email || "-"}
                  </span>
                </p>
                <p className={styles.passportItem}>
                  {"Nickname: "}
                  <span className={styles.passportItemData}>
                    {imxUser?.nickname || "-"}
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.logoutBtn} onClick={handleLogout}>
              {loading ? (
                <div className={styles.loaderWrapper}>
                  <div className={styles.roller}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>{" "}
                </div>
              ) : (
                "Logout"
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PassportModal;
