import { ENDPOINTS } from "configs/endpoints.config";

import { API_CONFIG } from "configs/api.config";
const { GET_STATISTICS, GET_LEADERS } = ENDPOINTS;
const { URL } = API_CONFIG;

export const GET_STATS = "GET_STATS";
export const getStats = () => {
  return {
    type: GET_STATS,
    request: {
      url: `${URL}${GET_STATISTICS}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};

export const GET_LEADER = "GET_LEADER";
export const getLeader = () => {
  return {
    type: GET_LEADER,
    request: {
      url: `${URL}${GET_LEADERS}`,
      method: "GET",
    },
    meta: {
      asPromise: true,
    },
  };
};
