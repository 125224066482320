import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useSelector, useDispatch } from "react-redux";
import { setConnectionType } from "store/vars/actions";
import { logout } from "store/auth/actions";

export const useCheckAddressAndConnection = () => {
  const { user, token } = useSelector((state) => state.auth);
  const { connectionType, imxUser } = useSelector((state) => state.vars);
  const { address, isConnected, isConnecting } = useAccount();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isConnected) {
      dispatch(setConnectionType("web3"));
    } else if (connectionType === "web3") {
      dispatch(setConnectionType(undefined));
    }
  }, [isConnected]);

  if (connectionType === "web3") {
    if (
      (token || token !== "") &&
      isConnected &&
      user?.address &&
      address &&
      address.toLowerCase() !== user.address.toLowerCase()
    ) {
      dispatch(logout());
    }

    if (!isConnecting && !isConnected && (token || token !== "")) {
      dispatch(logout());
    }
  }

  if (!connectionType && (token || token !== "")) {
    dispatch(logout());
  }
};
