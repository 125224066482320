import { SET_SINGULARITY_MOUNTED } from "./actions";

const initialState = {
  isSingularityMounted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGULARITY_MOUNTED: {
      return {
        ...state,
        isSingularityMounted: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
