import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import pathes from "configs/pathes";

export const PrivateRoute = ({ children, ...rest }) => {
  const token = useSelector((state) => state.auth.token);

  if (!token || token === "") {
    return <Navigate to={pathes.MAIN} replace {...rest} />;
  }

  return children;
};
