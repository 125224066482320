import { METAMASK_CONNECT, GET_BALANCE, GET_SIGN } from "./actions";

const initialState = {
  userAccount: "",
  tokenContract: {},
  balance: 0,
  balanceLP: 0,
  totalBalance: 0,
  sign: "",
  chainId: "",
  tokenContractLP: {},
  stakContract: {},
  stakAddr: "",
  myLPStake: 0,
  availToClaim: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case METAMASK_CONNECT: {
      return {
        ...state,
        userAccount: action.payload.userAccount,
        tokenContract: action.payload.tokenContract,
        chainId: action.payload.chain,
        tokenContractLP: action.payload.tokenContractLP,
      };
    }

    case GET_BALANCE: {
      return {
        ...state,
        balance: action.payload.balance,
        balanceLP: action.payload.balanceLP,
        totalBalance: action.payload.totalBalance,
        myLPStake: action.payload.myLPStake,
        availToClaim: action.payload.availToClaim,
      };
    }

    case GET_SIGN: {
      return {
        ...state,
        sign: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
